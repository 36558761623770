// import { useLocation } from "react-router-dom";

/* eslint-disable jsx-a11y/iframe-has-title */
const PreviewPage = () => {
    // const location = useLocation();
    return (
        <div className="absolute left-0 right-0 top-0 bottom-0">
            <iframe src={`https://docs.google.com/gview?url=https://pslb3.menlhk.go.id/internal/uploads/pengumuman/1545111808_contoh-pdf.pdf&embedded=true`} onLoad={() => console.log("oke")} className="w-full h-full"></iframe>
        </div>
    );
}

export default PreviewPage;