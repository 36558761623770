import { useEffect, useState } from "react";
import { ButtonRounded } from "../../../../components";

const MunisiContentFilter = ({
    onDone,
}) => {
    const [controller, setController] = useState({
        category: '',
    });
    const [category, setCategory] = useState([
        {
            key: 'mlrs-astros-ii-mk-6',
            title: 'MLRS Astros II MK 6',
            isActive: true,
        },
        {
            key: 'meriam-155-mm-gs-caesar',
            title: 'Meriam 155 mm GS Caesar',
            isActive: false,
        },
        {
            key: 'meriam-155-mm-gs-m109a4',
            title: 'Meriam 155 mm GS M109A4',
            isActive: false,
        },
        {
            key: 'meriam-155-mm-tarik-kh-179',
            title: 'Meriam 155 mm Tarik KH 179',
            isActive: false,
        },
        {
            key: 'meriam-105-mm-tarik-kh-178',
            title: 'Meriam 105 mm Tarik KH 178',
            isActive: false,
        },
        {
            key: 'meriam-105-mm-tarik-m101a1',
            title: 'Meriam 105 mm Tarik M101A1',
            isActive: false,
        },
        {
            key: 'meriam-76-mm-tarik-m48',
            title: 'Meriam 76 mm Tarik M48',
            isActive: false,
        },
        {
            key: 'meriam-75-mm-tarik-salunting-gun',
            title: 'Meriam 75 mm Tarik Salunting Gun',
            isActive: false,
        },
    ]);

    const onChangeCategory = (index) => {
        category.forEach((item) => {
            item.isActive = false;
        });

        category[index].isActive = true;
        setCategory([...category]);
        setController({ category: category[index].key });
    }

    const onFilter = () => {
        onDone && onDone(controller);
    };

    useEffect(() => {
        onChangeCategory(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="leading-3">
                <span className="text-base font-medium">Terapkan Filter Munisi</span>
                <br />
                <small>Klik item jika akan memilih</small>
            </div>
            <div className="mt-4">
                <span className="font-medium">Kategori</span>
                <div className="flex flex-col gap-2 mt-1">
                    {
                        category.map((item, index) => {
                            return (
                                <div key={index} className={`border rounded-md px-3 py-1 whitespace-pre cursor-pointer ${item.isActive ? 'bg-slate-800 text-white' : ''}`} onClick={() => onChangeCategory(index)}>
                                    <span className="text-sm">{item.title ?? ''}</span>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="mt-10 flex justify-end">
                <ButtonRounded title={<div className="flex gap-2 items-center" onClick={() => onFilter()}>
                    Terapkan
                </div>} styles={"border py-[4px] px-5 rounded bg-red-800 text-white"} />
            </div>
        </>
    );
}

export default MunisiContentFilter;