import { useEffect, useState } from "react";
import { ButtonRounded } from "../../../../components";

const AlutsistaContentFilter = ({
    onDone,
}) => {
    const [controller, setController] = useState({
        category: '',
        type: '',
    });
    const [category, setCategory] = useState([
        {
            title: "yonarmed-rocket",
            isActive: false,
            type: [
                {
                    key: 'MLRS-Astros-II-MK-6',
                    title: 'MLRS Astros II MK 6',
                    isActive: false,
                },
                {
                    key: 'Peluru-Kendali',
                    title: 'Peluru Kendali',
                    isActive: false,
                },
            ]
        },
        {
            title: "yonarmed-sedang",
            isActive: false,
            type: [
                {
                    key: 'Meriam-155-mm-GS-Caesar',
                    title: 'Meriam 155 mm GS Caesar',
                    isActive: false,
                },
                {
                    key: 'Meriam-155-mm-GS-M109A4BE',
                    title: 'Meriam 155 mm GS M109A4BE',
                    isActive: false,
                },
                {
                    key: 'Meriam-155-mm-Tarik-KH-179',
                    title: 'Meriam 155 mm Tarik KH 179',
                    isActive: false,
                },
            ]
        },
        {
            title: "yonarmed-ringan",
            isActive: false,
            type: [
                {
                    key: 'Meriam-105-mm-Tarik-KH-178',
                    title: 'Meriam 105 mm Tarik KH 178',
                    isActive: false,
                },
                {
                    key: 'Meriam-105-mm-Tarik-M101A1',
                    title: 'Meriam 105 mm Tarik M101A1',
                    isActive: false,
                },
                {
                    key: 'Meriam-76-mm-Tarik-M48',
                    title: 'Meriam 76 mm Tarik M48',
                    isActive: false,
                },
                {
                    key: 'Meriam-75-mm-Tarik-Saluting-Gun',
                    title: 'Meriam 75 mm Tarik Saluting Gun',
                    isActive: false,
                },
            ]
        },
    ]);

    const [type, setType] = useState([]);

    const onSetController = (field, value) => {
        setController({ ...controller, [field]: value });
    }

    const onChangeCategory = (index) => {
        category.forEach((item) => {
            item.isActive = false;
        });

        category[index].type.forEach((item) => {
            item.isActive = false;
        });

        category[index].isActive = true;
        setCategory([...category]);
        setType([...category[index].type]);
        setController({ category: category[index].title });
    }

    const onChangeType = (index) => {
        type.forEach((item) => {
            item.isActive = false;
        });

        type[index].isActive = true;
        setType([...type]);
        onSetController("type", type[index].key);
    }

    const onFilter = () => {
        onDone && onDone(controller);
    };

    useEffect(() => {
        onChangeCategory(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="leading-3">
                <span className="text-base font-medium">Terapkan Filter Alutsista</span>
                <br />
                <small>Klik item jika akan memilih</small>
            </div>
            <div className="mt-4">
                <span className="font-medium">Kategori</span>
                <div className="flex flex-col gap-2 mt-1">
                    {
                        category.map((item, index) => {
                            return (
                                <div key={index} className={`border rounded-md px-3 py-1 whitespace-pre cursor-pointer ${item.isActive ? 'bg-slate-800 text-white' : ''}`} onClick={() => onChangeCategory(index)}>
                                    <span className="text-sm">{item.title ?? ''}</span>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="mt-4">
                <span className="font-medium">Jenis</span>
                <div className="flex flex-col gap-2 mt-1">
                    {
                        type.map((item, index) => {
                            return (
                                <div key={index} className={`border rounded-md px-3 py-1 whitespace-pre cursor-pointer ${item.isActive ? 'bg-slate-800 text-white' : ''}`} onClick={() => onChangeType(index)}>
                                    <span className="text-sm">{item.title ?? ''}</span>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="mt-10 flex justify-end">
                <ButtonRounded title={<div className="flex gap-2 items-center" onClick={() => onFilter()}>
                    Terapkan
                </div>} styles={"border py-[4px] px-5 rounded bg-red-800 text-white"} />
            </div>
        </>
    );
}

export default AlutsistaContentFilter;