import { useEffect, useState } from "react";
import { ButtonRounded } from "../../../../components";

const PejabatSatuanContentFilter = ({
    onDone,
}) => {
    const [controller, setController] = useState({
        category: '',
    });
    const [category, setCategory] = useState([
        {
            key: 'pusenarmed',
            title: 'Pusenarmed',
            isActive: true,
        },
        {
            key: 'pusdikarmed',
            title: 'Pusdikarmed',
            isActive: false,
        },
        {
            key: 'dansat-armed',
            title: 'Dansat Armed',
            isActive: false,
        },
    ]);

    const onChangeCategory = (index) => {
        category.forEach((item) => {
            item.isActive = false;
        });

        category[index].isActive = true;
        setCategory([...category]);
        setController({ category: category[index].key });
    }

    const onFilter = () => {
        onDone && onDone(controller);
    };

    useEffect(() => {
        onChangeCategory(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="leading-3">
                <span className="text-base font-medium">Terapkan Filter Pejabat Satuan Armed</span>
                <br />
                <small>Klik item jika akan memilih</small>
            </div>
            <div className="mt-4">
                <span className="font-medium">Kategori</span>
                <div className="flex flex-col gap-2 mt-1">
                    {
                        category.map((item, index) => {
                            return (
                                <div key={index} className={`border rounded-md px-3 py-1 whitespace-pre cursor-pointer ${item.isActive ? 'bg-slate-800 text-white' : ''}`} onClick={() => onChangeCategory(index)}>
                                    <span className="text-sm">{item.title ?? ''}</span>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
            <div className="mt-10 flex justify-end">
                <ButtonRounded title={<div className="flex gap-2 items-center" onClick={() => onFilter()}>
                    Terapkan
                </div>} styles={"border py-[4px] px-5 rounded bg-red-800 text-white"} />
            </div>
        </>
    );
}

export default PejabatSatuanContentFilter;