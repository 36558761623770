import { useEffect, useRef, useState } from "react";
import { ButtonRounded } from "../../../../components";
import AlutsistaContentFilter from "../content_filter/AlutsistaContentFilter";
import MunisiContentFilter from "../content_filter/MunisiContentFilter";
import PejabatSatuanContentFilter from "../content_filter/PejabatSatuanContentFilter";
import ResponsibilityContentFilter from "../content_filter/ResponsibilityContentFilter";

const FilterLearningModal = ({
    category,
    onFilter,
}) => {
    const ref = useRef();
    const [element, setElement] = useState(<></>);
    const [isShow, setIsShow] = useState(false);

    const onGetCategory = ({ category }) => {
        switch (category.key) {
            case "alutsista":
                setElement(<AlutsistaContentFilter onDone={(value) => { onFilter && onFilter(category.key, value); setIsShow(false); }} />);
                break;
            case "munisi":
                setElement(<MunisiContentFilter onDone={(value) => { onFilter && onFilter(category.key, value); setIsShow(false); }} />);
                break;
            case "tugas-dan-tanggung-jawab":
                setElement(<ResponsibilityContentFilter onDone={(value) => { onFilter && onFilter(category.key, value); setIsShow(false); }} />);
                break;
            case "satuan":
                setElement(satuan());
                break;
            case "pejabat-satuan":
                setElement(<PejabatSatuanContentFilter onDone={(value) => { onFilter && onFilter(category.key, value); setIsShow(false); }} />);
                break;
            default:
                break;
        }
    }

    const satuan = () => {
        return (
            <span>Tidak ada filter untuk menu satuan</span>
        );
    }

    const toogleModal = () => {
        !isShow === true && onGetCategory({ category: category });
        setIsShow(!isShow);
    };

    const handleClickOutside = (event) => {
        if (!ref?.current?.contains(event.target)) {
            setIsShow(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [category]);

    return (
        <div className="inline-block" ref={ref}>
            <div className="flex md:px-5 mt-5 justify-start">
                <ButtonRounded title={<div className="flex gap-2 items-center" onClick={() => toogleModal()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M4 4h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v7l-6 2v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227z" /></svg>
                    Filter
                </div>} styles={"border py-[4px] px-[15px] rounded"} />
            </div>
            <div className={`fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center z-10 ${!isShow && "hidden"}`}>
                <div className="absolute h-full w-full bg-black opacity-30 z-10" onClick={() => toogleModal()}></div>
                <div className="p-3 border rounded-lg bg-white w-96 z-10 mx-3">
                    {element}
                </div>
            </div>
        </div>
    )
}

export default FilterLearningModal;