import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLearningAlutsistaRequest, getLearningMunisiRequest, getLearningPejabatSatuanRequest, getLearningResponsibilityRequest, getLearningSatuanRequest } from "../../api/LearningRequest";

const LearningContext = createContext();

export const LearningContextProvider = ({ children }) => {
    const navigation = useNavigate();
    const [categoryCurrent, setCategoryCurrent] = useState({});
    const [category, setCategory] = useState([
        {
            title: 'Data Alutsista',
            key: 'alutsista',
            onLoad: () => onGetAlutsista({}),
            isActive: false,
        },
        {
            title: 'Data Munisi',
            key: 'munisi',
            onLoad: () => onGetMunisi({}),
            isActive: false,
        },
        {
            title: 'Tugas dan Tanggung Jawab',
            key: 'tugas-dan-tanggung-jawab',
            onLoad: () => onGetResponsibility({}),
            isActive: false,
        },
        {
            title: 'Lambang dan Sejarah Satuan',
            key: 'satuan',
            onLoad: () => onGetSatuan(),
            isActive: false,
        },
        {
            title: 'Pejabat Satuan Armed',
            key: 'pejabat-satuan',
            onLoad: () => onGetPejabatSatuan({}),
            isActive: false,
        },
    ]);
    const [learning, setLearning] = useState({});

    const onChangeCategory = (index) => {
        category.forEach((item) => {
            item.isActive = false;
        });

        category[index].isActive = true;
        setCategoryCurrent(category[index]);
        setCategory(category);
        category[index].onLoad();
    }

    const onGetAlutsista = async ({ category, type }) => {
        await getLearningAlutsistaRequest({ filter: `category=${category ?? ""}&type=${type ?? ""}` }).then((res) => {
            setLearning(res);
        });
    }

    const onGetMunisi = async ({ category }) => {
        await getLearningMunisiRequest({ filter: `category=${category ?? ""}` }).then((res) => {
            setLearning(res);
        });
    }

    const onGetResponsibility = async ({ category, type }) => {
        await getLearningResponsibilityRequest({ filter: `category=${category ?? ""}&type=${type ?? ""}` }).then((res) => {
            setLearning(res);
        });
    }

    const onGetSatuan = async () => {
        await getLearningSatuanRequest({}).then((res) => {
            setLearning(res);
        });
    }

    const onGetPejabatSatuan = async ({ category }) => {
        await getLearningPejabatSatuanRequest({ filter: `category=${category ?? ""}` }).then((res) => {
            setLearning(res);
        });
    }

    const onFilter = (field, value) => {
        switch (field) {
            case "alutsista":
                onGetAlutsista(value);
                break;
            case "munisi":
                onGetMunisi(value);
                break;
            case "tugas-dan-tanggung-jawab":
                onGetResponsibility(value);
                break;
            case "satuan":

                break;
            case "pejabat-satuan":
                onGetPejabatSatuan(value)
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        onChangeCategory(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LearningContext.Provider value={{ navigation, category, categoryCurrent, learning, onChangeCategory, onFilter }}>
            {children}
        </LearningContext.Provider>
    );
}

export const UseLearningContext = () => {
    return useContext(LearningContext);
}