import { useNavigate } from "react-router-dom";
import { imgBg } from "../../../assets";
import { Button, Card, Content } from "../../../components";

const EmptyPage = () => {
    const navigation = useNavigate();
    return (
        <Content>
            <div className="absolute top-0 bottom-0 left-0 right-0 bg-gradient-to-r from-red-100 animate-pulse" />
            <div className="absolute top-0 bottom-0 left-0 right-0 opacity-[0.15] overflow-hidden flex justify-center items-end">
                <img src={imgBg} alt="BackgroundApp" className="object-cover w-full" />
            </div>
            <div className="fixed top-0 bottom-0 left-0 right-0 overflow-auto flex justify-center items-center">
                <Card styles="w-[90%] md:w-[29rem]">
                    <div className="px-3 py-8">
                        <div className="flex justify-center">
                            <span className="font-medium text-lg">Izin Akses Belum Diberikan</span>
                        </div>
                        <div className="text-center">
                            <span>Silahkan hubungi admin untuk perizinan akses!</span>
                        </div>
                        <div className="mt-5 flex justify-center">
                            <Button className="bg-red-800 text-white" onClick={() => navigation('/')}>Kembali</Button>
                        </div>
                    </div>
                </Card>
            </div>
        </Content>
    );
}

export default EmptyPage;